import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
  GET_ALL_GEOFENCES,
  CREATE_GEOFENCE,
  EDIT_GEOFENCE,
  DELETE_GEOFENCE,
  GET_GEOFENCE_DETAILS
} from 'constants/ActionTypes';
import { getAllGeofencesSuccess, getGeofenceDetailsSuccess, selectGeofence } from '../actions/Geofences';
import { setMarkers, mapSetWaypoints } from '../actions/Map';
import { showMessage, hideLoader, showLoader } from '../actions/Settings';
import {
  getAllGeofencesApi,
  createGeofenceApi,
  editGeofenceApi,
  deleteGeofenceApi,
  getGeofenceDetailsApi
} from '../../api';
import { history } from 'appRedux/store';
import Moment from 'moment';

function* getAllGeofencesHandler() {
  try {
    const res = yield call(getAllGeofencesApi);
    yield put(getAllGeofencesSuccess(res.geofences));
    yield put(selectGeofence(res.geofences[0]));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* getGeofenceDetailsHandler({ payload }) {
  const { geofenceId, form } = payload;
  try {
    const res = yield call(getGeofenceDetailsApi, geofenceId);
    if (form) form.setFieldsValue(res);
    yield put(getGeofenceDetailsSuccess(res));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* createGeofenceHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(createGeofenceApi, payload);
    yield put(hideLoader());
    history.push('/geofences');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* editGeofenceHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(editGeofenceApi, payload);
    yield put(hideLoader());
    history.push('/geofences');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* deleteGeofenceHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(deleteGeofenceApi, payload);
    yield put(hideLoader());
    history.push('/geofences');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

export function* getAllGeofencesSaga() {
  yield takeEvery(GET_ALL_GEOFENCES, getAllGeofencesHandler);
}

export function* getGeofenceDetailsSaga() {
  yield takeEvery(GET_GEOFENCE_DETAILS, getGeofenceDetailsHandler);
}

export function* createGeofenceSaga() {
  yield takeEvery(CREATE_GEOFENCE, createGeofenceHandler);
}

export function* editGeofenceSaga() {
  yield takeEvery(EDIT_GEOFENCE, editGeofenceHandler);
}

export function* deleteGeofenceSaga() {
  yield takeEvery(DELETE_GEOFENCE, deleteGeofenceHandler);
}

export default function* rootSaga() {
  yield all([
    fork(getAllGeofencesSaga),
    fork(getGeofenceDetailsSaga),
    fork(createGeofenceSaga),
    fork(editGeofenceSaga),
    fork(deleteGeofenceSaga)
  ]);
}
