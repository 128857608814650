import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
  GET_ALL_ASSETS,
  GET_ALL_MODELS,
  CREATE_ASSET,
  EDIT_ASSET,
  DELETE_ASSET,
  GET_ASSET_DETAILS,
  PLAN_TRIP,
  GET_ASSET_TRIPS,
  GET_TRIP_DETAILS,
  GET_REPORTS,
  SELECT_ASSET,
  SEARCH_ASSETS,
  GET_DRIVER_DETAILS,
  GET_DRIVER_DETAILS_SUCCESS
} from 'constants/ActionTypes';
import {
  getAllAssetsSuccess,
  getAssetDetailsSuccess,
  getAllModelsSuccess,
  getAssetTripsSuccess,
  getTripDetailsSuccess,
  getReportsSuccess,
  setImportantDates,
  searchAssetsSuccess,
  getDriverDetailsSuccess
} from '../actions/Assets';
import {
  setMarkers,
  mapSetWaypoints,
  setCurrentAddress,
  showHint,
  setMarkersSuccess,
  setAssetDetailShowMarker
} from '../actions/Map';
import { showMessage, hideLoader, showLoader } from '../actions/Settings';
import {
  getAllAssetsApi,
  getAllModelsApi,
  createAssetApi,
  editAssetApi,
  deleteAssetApi,
  getAssetDetailsApi,
  createTripPlanApi,
  getAssetTripsApi,
  getTripDetailsApi,
  getReportMovements,
  getReportLocations,
  getReportWeather,
  getAllAssetsSearchApi,
  getDriverDetailsApi
} from '../../api';
import { history } from 'appRedux/store';
import Moment from 'moment';

function* getAllAssetsHandler() {
  try {
    const res = yield call(getAllAssetsApi);
    yield put(getAllAssetsSuccess(res.assets));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* getAllAssetsSearchHandler({ payload }) {
  try {
    const res = yield call(getAllAssetsSearchApi, payload);
    yield put(searchAssetsSuccess(res.assets));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* getAllModelsHandler() {
  try {
    const res = yield call(getAllModelsApi);
    yield put(getAllModelsSuccess(res.models));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* createAssetHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(createAssetApi, payload);
    yield put(hideLoader());
    history.push('/assets');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* editAssetHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(editAssetApi, payload);
    yield put(hideLoader());
    history.push(`/assets/details/${payload.id}`);
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* deleteAssetHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(deleteAssetApi, payload);
    yield put(hideLoader());
    history.push('/assets');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* getAssetDetailsHandler({ payload }) {
  const { assetId, form } = payload;
  try {
    const { map } = yield select();
    const res = yield call(getAssetDetailsApi, assetId);
    if (form)
      form.setFieldsValue({
        ...res,
        tags: res.tags ? res.tags.split(' ') : [],
        purchaseDate: Moment(res.purchaseDate)
      });

    const marker = {
      id: res.id,
      status: res.status,
      lat: res.currentLocation.latitude,
      lng: res.currentLocation.longitude,
      address: res.currentAddress
    };

    yield all([
      put(getAssetDetailsSuccess(res)),
      put(setImportantDates(res.importantDates.map(date => ({ ...date, date: Moment(date.date) })))),
      put(setMarkersSuccess([marker]))
    ]);
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* getDriverDetailsHandler({payload}){
console.log('payload',payload)
  try {
    const res = yield call(getDriverDetailsApi, payload);
    yield all([
      put(getDriverDetailsSuccess(res))
    ]);
  } catch (err) {
    yield put(showMessage(err.message));
  }
}


function* planTripHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(createTripPlanApi, payload);
    yield put(hideLoader());
    history.push(`/assets/details/${payload.assetId}`);
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* getAssetTripsHandler({ payload }) {
  try {
    const res = yield call(getAssetTripsApi, payload);
    yield put(getAssetTripsSuccess(res.trips));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* getTripDetailsHandler({ payload }) {
  try {
    const res = yield call(getTripDetailsApi, payload);
    yield put(getTripDetailsSuccess(res));
    yield put(mapSetWaypoints(res.waypoints));
    yield put(setAssetDetailShowMarker(false));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* getReportsHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(getReportMovements, payload);
    const weathers = yield call(getReportWeather, payload);
    const locations = yield call(getReportLocations, payload);
    yield put(
      getReportsSuccess({
        averageSpeed: res.averageSpeed,
        distanceTravelledInKm: res.distanceTravelledInKm,
        location: locations.records,
        weather: weathers.records
      })
    );
    yield put(hideLoader());
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* selectAssetHandler({ payload }) {
  yield all([put(setCurrentAddress(payload)), put(showHint())]);
}

export function* getAllAssetsSaga() {
  yield takeEvery(GET_ALL_ASSETS, getAllAssetsHandler);
}

export function* getAllAssetsSearchSaga() {
  yield takeEvery(SEARCH_ASSETS, getAllAssetsSearchHandler);
}


export function* getAllModelsSaga() {
  yield takeEvery(GET_ALL_MODELS, getAllModelsHandler);
}

export function* createAssetSaga() {
  yield takeEvery(CREATE_ASSET, createAssetHandler);
}

export function* editAssetSaga() {
  yield takeEvery(EDIT_ASSET, editAssetHandler);
}

export function* deleteAssetSaga() {
  yield takeEvery(DELETE_ASSET, deleteAssetHandler);
}

export function* getAssetDetailsSaga() {
  yield takeEvery(GET_ASSET_DETAILS, getAssetDetailsHandler);
}

export function* getDriverDetailsSaga() {
  yield takeEvery(GET_DRIVER_DETAILS, getDriverDetailsHandler);
}

export function* planTripSaga() {
  yield takeEvery(PLAN_TRIP, planTripHandler);
}

export function* getAssetTripsSaga() {
  yield takeEvery(GET_ASSET_TRIPS, getAssetTripsHandler);
}

export function* getTripDetailsSaga() {
  yield takeEvery(GET_TRIP_DETAILS, getTripDetailsHandler);
}

export function* getReportsSaga() {
  yield takeEvery(GET_REPORTS, getReportsHandler);
}

export function* selectAssetSaga() {
  yield takeEvery(SELECT_ASSET, selectAssetHandler);
}

export default function* rootSaga() {
  yield all([
    fork(getAllAssetsSaga),
    fork(getAllModelsSaga),
    fork(createAssetSaga),
    fork(editAssetSaga),
    fork(deleteAssetSaga),
    fork(getAssetDetailsSaga),
    fork(getDriverDetailsSaga),
    fork(planTripSaga),
    fork(getAssetTripsSaga),
    fork(getTripDetailsSaga),
    fork(getReportsSaga),
    fork(selectAssetSaga),
    fork(getAllAssetsSearchSaga)
  ]);
}
