import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Settings from './Settings';
import Auth from './Auth';
import Assets from './Assets';
import Users from './Users';
import Sensors from './Sensors';
import Alerts from './Alerts';
import Map from './Map';
import Geofences from './Geofences';

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  assets: Assets,
  users: Users,
  sensors: Sensors,
  alerts: Alerts,
  map: Map,
  geofences: Geofences
});

export default reducers;
