import {
  GET_ALL_SENSORS_SUCCESS,
  GET_SENSOR_DETAILS_SUCCESS,
  GET_ALL_SENSOR_MODELS_SUCCESS,
  SIGNOUT_USER
} from 'constants/ActionTypes';

const INIT_STATE = {
  allSensors: [],
  currentSensor: {},
  models: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_SENSORS_SUCCESS: {
      return {
        ...state,
        allSensors: action.payload
      };
    }
    case GET_SENSOR_DETAILS_SUCCESS: {
      return {
        ...state,
        currentSensor: action.payload
      };
    }
    case GET_ALL_SENSOR_MODELS_SUCCESS: {
      return {
        ...state,
        models: action.payload
      };
    }
    case SIGNOUT_USER: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
