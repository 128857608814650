import {
  GET_ALL_ASSETS,
  GET_ALL_ASSETS_SUCCESS,
  FILTER_ASSETS,
  GET_ALL_MODELS,
  GET_ALL_MODELS_SUCCESS,
  CREATE_ASSET,
  EDIT_ASSET,
  DELETE_ASSET,
  GET_ASSET_DETAILS,
  GET_ASSET_DETAILS_SUCCESS,
  GET_DRIVER_DETAILS,
  GET_DRIVER_DETAILS_SUCCESS,
  SET_IMPORTANT_DATES,
  PLAN_TRIP,
  SELECT_ASSET,
  GET_ASSET_TRIPS,
  GET_ASSET_TRIPS_SUCCESS,
  GET_TRIP_DETAILS,
  GET_TRIP_DETAILS_SUCCESS,
  GET_REPORTS,
  GET_REPORTS_SUCCESS,
  SEARCH_ASSETS,
  SEARCH_ASSETS_SUCCESS
} from 'constants/ActionTypes';

export const getAllAssets = () => {
  return {
    type: GET_ALL_ASSETS
  };
};

export const getAllAssetsSuccess = assets => {
  return {
    type: GET_ALL_ASSETS_SUCCESS,
    payload: assets
  };
};

export const searchAssets = query => {
  return {
    type: SEARCH_ASSETS,
    payload: query
  };
};
export const searchAssetsSuccess = assets => {
  return {
    type: SEARCH_ASSETS_SUCCESS,
    payload: assets
  };
};

export const filterAssets = status => {
  return {
    type: FILTER_ASSETS,
    payload: status
  };
};


export const getAllModels = () => {
  return {
    type: GET_ALL_MODELS
  };
};

export const getAllModelsSuccess = models => {
  return {
    type: GET_ALL_MODELS_SUCCESS,
    payload: models
  };
};

export const createAsset = asset => {
  return {
    type: CREATE_ASSET,
    payload: asset
  };
};

export const editAsset = asset => {
  return {
    type: EDIT_ASSET,
    payload: asset
  };
};

export const deleteAsset = assetId => {
  return {
    type: DELETE_ASSET,
    payload: assetId
  };
};

export const getAssetDetails = (assetId, form) => {
  return {
    type: GET_ASSET_DETAILS,
    payload: { assetId, form }
  };
};

export const getAssetDetailsSuccess = asset => {
  return {
    type: GET_ASSET_DETAILS_SUCCESS,
    payload: asset
  };
};

export const getDriverDetails = (assetId,from,to,pageNumber,pageSize)=>{
  return {
    type: GET_DRIVER_DETAILS,
    payload: {assetId,from,to,pageNumber,pageSize}
  };
}

export const getDriverDetailsSuccess = driverDetails => {
  
  return {
    type: GET_DRIVER_DETAILS_SUCCESS,
    payload: driverDetails
  };
};

export const setImportantDates = payload => {
  return {
    type: SET_IMPORTANT_DATES,
    payload
  };
};

export const planTrip = trip => {
  return {
    type: PLAN_TRIP,
    payload: trip
  };
};

export const selectAsset = asset => {
  return {
    type: SELECT_ASSET,
    payload: asset
  };
};

export const getAssetTrips = assetId => {
  return {
    type: GET_ASSET_TRIPS,
    payload: assetId
  };
};

export const getAssetTripsSuccess = trips => {
  return {
    type: GET_ASSET_TRIPS_SUCCESS,
    payload: trips
  };
};

export const getTripDetails = assetId => {
  return {
    type: GET_TRIP_DETAILS,
    payload: assetId
  };
};

export const getTripDetailsSuccess = tripDetails => {
  return {
    type: GET_TRIP_DETAILS_SUCCESS,
    payload: tripDetails
  };
};

export const getReports = (assetId, from, to) => {
  return {
    type: GET_REPORTS,
    payload: { assetId, from, to }
  };
};

export const getReportsSuccess = reports => {
  return {
    type: GET_REPORTS_SUCCESS,
    payload: reports
  };


  
};
