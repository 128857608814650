import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";

class SidebarContent extends Component {
  getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile />
            {/* <AppsNavigation /> */}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              <Menu.Item key="dashboard">
                <Link to="/dashboard">
                  <i className="icon icon-card" />
                  <span>Dashboard</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="assets">
                <Link to="/assets">
                  <i className="icon icon-view-o" />
                  <span>Assets</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="geofences">
                <Link to="/geofences">
                  <i className="icon icon-geo-location" />
                  <span>Geofences</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="alerts">
                <Link to="/alerts">
                  <i className="icon icon-alert" />
                  <span>Alerts</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="reports">
                <Link to="/reports">
                  <i className="icon icon-chart" />
                  <span>Reports</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="devices">
                <Link to="/devices">
                  <i className="icon icon-apps" />
                  <span>Device Management</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="users">
                <Link to="/users">
                  <Icon style={{ fontSize: "20px" }} type="usergroup-add" />
                  <span>Users Management</span>
                </Link>
              </Menu.Item>

              {this.props.user.role === "SysAdmin" && (
                <Menu.Item key="tenants">
                  <Link to="/tenants">
                    <Icon style={{ fontSize: "20px" }} type="build" />
                    <span>Tenants Management</span>
                  </Link>
                </Menu.Item>
              )}

              {this.props.user.role === "SysAdmin" && (
                <Menu.Item key="models">
                  <Link to="/models">
                    <Icon style={{ fontSize: "20px" }} type="radius-setting" />
                    <span>Models Management</span>
                  </Link>
                </Menu.Item>
              )}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { user } = auth;

  return { navStyle, themeType, locale, pathname, user };
};
export default connect(mapStateToProps)(SidebarContent);
