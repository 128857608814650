import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import {
  MAP_FIT_BOUNDS,
  SET_MARKERS,
  SET_MAP_API,
  MAP_SET_WAYPOINTS,
  SET_CURRENT_ADDRESS,
  SET_ASSET_DETAIL_SHOW_MARKER,
} from "constants/ActionTypes";
import {
  mapFitBounds,
  setMarkersSuccess,
  mapSetDirectionResult,
  setCurrentAddressSuccess,
} from "../actions/Map";
import { showMessage, hideLoader, showLoader } from "../actions/Settings";
import { history } from "appRedux/store";
import { getAllAssetsApi } from "api";
import { store } from "NextApp";

function* mapFitBoundsHandler({ payload }) {
  try {
    const state = yield select();
    const { map } = state;
    const { markers } = map;
    const { mapInstance, LatLngBounds, LatLng } = payload;
    const bounds = new LatLngBounds();
    markers.forEach((marker) =>
      bounds.extend(new LatLng(marker.lat, marker.lng))
    );
    const newBounds = {
      ne: {
        lat: bounds.getNorthEast().lat(),
        lng: bounds.getNorthEast().lng(),
      },
      sw: {
        lat: bounds.getSouthWest().lat(),
        lng: bounds.getSouthWest().lng(),
      },
    };
    mapInstance.fitBounds(bounds);
  } catch (err) {
    // yield put(showMessage(err.message));
  }
}

function* setMarkersHandler({ payload }) {
  try {
    const res = yield call(getAllAssetsApi);
    yield put(
      setMarkersSuccess(
        res.assets.map((asset) => ({
          id: asset.id,
          status: asset.status,
          lat: asset.currentLocation.latitude,
          lng: asset.currentLocation.longitude,
          address: asset.currentAddress,
        }))
      )
    );
    yield put(mapFitBounds(payload));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* mapSetWaypointsHandler({ payload }) {
  try {
    yield put(showLoader());
    const state = yield select();
    const { map } = state;
    const { mapInstance, LatLng, directionsService, directionsRenderer } = map;
    const first = payload[0];
    const last = payload[payload.length - 1];
    const waypoints = payload.slice().splice(1, payload.length - 2);
    const directionReq = {
      origin: new LatLng(first.latitude, first.longitude),
      destination: new LatLng(last.latitude, last.longitude),
      waypoints: waypoints.map((point) => ({
        location: new LatLng(point.latitude, point.longitude),
        stopover: false,
      })),
      travelMode: "DRIVING",
    };

    let dirResult;
    directionsRenderer.setMap(mapInstance);
    directionsService.route(directionReq, (result, status) => {
      if (status == "OK") {
        directionsRenderer.setDirections(result);
        dirResult = {
          origin: result.request.origin.location,
          destination: result.request.destination.location,
          distance: result.routes[0].legs[0].distance,
          duration: result.routes[0].legs[0].duration,
          endAddress: result.routes[0].legs[0].end_address,
          startAddress: result.routes[0].legs[0].start_address,
          summary: result.routes[0].summary,
        };
        store.dispatch(mapSetDirectionResult(dirResult));
      } else {
        store.dispatch(showMessage("No routes found!"));
      }
      store.dispatch(hideLoader());
    });
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* setMapApiHandler() {
  yield put(mapFitBounds());
}

function* setCurrentAddressHandler({ payload }) {
  try {
    const state = yield select();
    const { map } = state;
    const marker = map.markers.find((marker) => marker.id === payload.id);
    // map.geocoder.geocode({ location: { lat: marker.lat, lng: marker.lng } }, (results, status) => {
    //   if (status === 'OK') {
    //     if (results[0]) {
    //       store.dispatch(setCurrentAddressSuccess(results[0].formatted_address));
    //     } else {
    //       store.dispatch(showMessage('No results found'));
    //     }
    //   } else {
    //     store.dispatch(showMessage('Geocoder failed due to: ' + status));
    //   }
    // });
    yield put(setCurrentAddressSuccess(marker.address));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

function* setAssetDetailShowMarkerHandler({ payload }) {
  if (payload) {
    const state = yield select();
    const { map } = state;
    map.directionsRenderer.setMap(null);
  }
}

export function* mapFitBoundsSaga() {
  yield takeEvery(MAP_FIT_BOUNDS, mapFitBoundsHandler);
}

export function* setMarkersSaga() {
  yield takeEvery(SET_MARKERS, setMarkersHandler);
}

export function* setMapApiSaga() {
  yield takeEvery(SET_MAP_API, setMapApiHandler);
}

export function* mapSetWaypointsSaga() {
  yield takeEvery(MAP_SET_WAYPOINTS, mapSetWaypointsHandler);
}

export function* setCurrentAddressSaga() {
  yield takeEvery(SET_CURRENT_ADDRESS, setCurrentAddressHandler);
}

export function* setAssetDetailShowMarkerSaga() {
  yield takeEvery(
    SET_ASSET_DETAIL_SHOW_MARKER,
    setAssetDetailShowMarkerHandler
  );
}

export default function* rootSaga() {
  yield all([
    fork(mapFitBoundsSaga),
    fork(setMarkersSaga),
    fork(setMapApiSaga),
    fork(mapSetWaypointsSaga),
    fork(setCurrentAddressSaga),
    fork(setAssetDetailShowMarkerSaga),
  ]);
}
