import {
  GET_ALL_GEOFENCES_SUCCESS,
  GET_GEOFENCE_DETAILS_SUCCESS,
  SELECT_GEOFENCE,
  SIGNOUT_USER,
  SET_POINTS
} from 'constants/ActionTypes';

const INIT_STATE = {
  allGeofences: [],
  currentGeofence: {},
  selectedGeofence: {},
  points: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_GEOFENCES_SUCCESS: {
      return {
        ...state,
        allGeofences: action.payload,
        filteredGeofences: action.payload
      };
    }
    case GET_GEOFENCE_DETAILS_SUCCESS: {
      return {
        ...state,
        currentGeofence: action.payload
      };
    }
    case SELECT_GEOFENCE: {
      return {
        ...state,
        selectedGeofence: action.payload
      };
    }
    case SET_POINTS: {
      return {
        ...state,
        points: action.payload
      };
    }
    case SIGNOUT_USER: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
