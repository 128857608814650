import { coreBase, apiBuilder, getAuthHeaders } from './index'

export const getAllGeofencesApi = () =>
  apiBuilder(`${coreBase}/geofences`, {
    method: 'GET',
    headers: getAuthHeaders()
  })

export const getGeofenceDetailsApi = (geofenceId) =>
  apiBuilder(`${coreBase}/geofences/${geofenceId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  })

export const createGeofenceApi = (geofence) =>
  apiBuilder(`${coreBase}/geofences`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ ...geofence, geoFenceSource: 1 })
  })

export const editGeofenceApi = (geofence) =>
  apiBuilder(`${coreBase}/geofences/${geofence.id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(geofence)
  })

export const deleteGeofenceApi = (geofenceId) =>
  apiBuilder(`${coreBase}/geofences/${geofenceId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  })
