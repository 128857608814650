import { authBase, apiBuilder, getAuthHeaders } from "./index";
export const getTenant = (id) =>
  apiBuilder(`${authBase}/tenants/${id}`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const getAllTenantsApi = () =>
  apiBuilder(`${authBase}/tenants/All`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const createTenantApi = (tenant) =>
  apiBuilder(`${authBase}/tenants`, {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify(tenant),
  });

export const editTenantApi = (id, tenant) =>
  apiBuilder(`${authBase}/tenants/${id}`, {
    method: "PUT",
    headers: getAuthHeaders(),
    body: JSON.stringify(tenant),
  });

export const deleteTenantApi = (id) =>
  apiBuilder(`${authBase}/tenants/${id}`, {
    method: "DELETE",
    headers: getAuthHeaders(),
  });
