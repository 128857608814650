import {
  UPDATE_MARKER,
  SET_MARKERS,
  SET_MARKERS_SUCCESS,
  SET_MAP_API,
  MAP_FIT_BOUNDS,
  MAP_SET_WAYPOINTS,
  MAP_SET_DIRECTION_RESULT,
  SET_CURRENT_ADDRESS,
  SET_CURRENT_ADDRESS_SUCCESS,
  SHOW_HINT,
  HIDE_HINT,
  SET_ASSET_DETAIL_SHOW_MARKER
} from 'constants/ActionTypes';

export const setMarkers = payload => {
  return {
    type: SET_MARKERS,
    payload
  };
};

export const setMarkersSuccess = markers => {
  return {
    type: SET_MARKERS_SUCCESS,
    payload: markers
  };
};

export const updateMarker = marker => {
  return {
    type: UPDATE_MARKER,
    payload: marker
  };
};

export const setMapApi = payload => {
  return {
    type: SET_MAP_API,
    payload
  };
};

export const mapFitBounds = payload => {
  return {
    type: MAP_FIT_BOUNDS,
    payload
  };
};

export const mapSetWaypoints = payload => {
  return {
    type: MAP_SET_WAYPOINTS,
    payload
  };
};

export const mapSetDirectionResult = payload => {
  return {
    type: MAP_SET_DIRECTION_RESULT,
    payload
  };
};

export const setCurrentAddress = selectedAsset => {
  return {
    type: SET_CURRENT_ADDRESS,
    payload: selectedAsset
  };
};

export const setCurrentAddressSuccess = currentAddress => {
  return {
    type: SET_CURRENT_ADDRESS_SUCCESS,
    payload: currentAddress
  };
};

export const showHint = () => {
  return {
    type: SHOW_HINT
  };
};

export const hideHint = () => {
  return {
    type: HIDE_HINT
  };
};

export const setAssetDetailShowMarker = payload => {
  return {
    type: SET_ASSET_DETAIL_SHOW_MARKER,
    payload
  };
};
