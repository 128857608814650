import {
  GET_ALL_ALERTS,
  GET_ALL_ALERTS_SUCCESS,
  FILTER_ALERTS,
  GET_ALERT_SETTINGS,
  GET_ALERT_SETTINGS_SUCCESS,
  EDIT_ALERT_SETTINGS
} from 'constants/ActionTypes';

export const getAllAlerts = () => {
  return {
    type: GET_ALL_ALERTS
  };
};

export const getAllAlertsSuccess = alerts => {
  return {
    type: GET_ALL_ALERTS_SUCCESS,
    payload: alerts
  };
};

export const filterAlerts = filter => {
  return {
    type: FILTER_ALERTS,
    payload: filter
  };
};

export const getAlertSettings = payload => {
  return {
    type: GET_ALERT_SETTINGS,
    payload
  };
};

export const getAlertSettingsSuccess = settings => {
  return {
    type: GET_ALERT_SETTINGS_SUCCESS,
    payload: settings
  };
};

export const editAlertSettings = settings => {
  return {
    type: EDIT_ALERT_SETTINGS,
    payload: settings
  };
};
