import { coreBase, apiBuilder, getAuthHeaders } from "./index";

export const getAllModelsApi = () =>
  apiBuilder(`${coreBase}/assets/models`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const getAllTypesApi = () =>
  apiBuilder(`${coreBase}/assets/types`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const getModel = (id) =>
  apiBuilder(`${coreBase}/assets/models/${id}`, {
    method: "GET",
    headers: getAuthHeaders(),
  });

export const createModelApi = (Model) =>
  apiBuilder(`${coreBase}/assets/models`, {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify(Model),
  });

export const editModelApi = (id, Model) =>
  apiBuilder(`${coreBase}/assets/models/${id}`, {
    method: "PUT",
    headers: getAuthHeaders(),
    body: JSON.stringify(Model),
  });

export const deleteModelApi = (id) =>
  apiBuilder(`${coreBase}/assets/models/${id}`, {
    method: "DELETE",
    headers: getAuthHeaders(),
  });
