import { coreBase, apiBuilder, getAuthHeaders } from './index'

export const getAllAssetsApi = () =>
  apiBuilder(`${coreBase}/assets`, {
    method: 'GET',
    headers: getAuthHeaders()
  })

export const getAllAssetsSearchApi = (query) =>
  apiBuilder(`${coreBase}/assets?query=${query}`, {
    method: 'GET',
    headers: getAuthHeaders()
  })

export const getAssetDetailsApi = (assetId) =>
  apiBuilder(`${coreBase}/assets/${assetId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  })

export const getAssetTripsApi = (assetId) =>
  apiBuilder(`${coreBase}/assets/${assetId}/report/trip/all`, {
    method: 'GET',
    headers: getAuthHeaders()
  })

export const createAssetApi = (asset) =>
  apiBuilder(`${coreBase}/assets`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(asset)
  })

export const editAssetApi = (asset) =>
  apiBuilder(`${coreBase}/assets/${asset.id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(asset)
  })

export const deleteAssetApi = (assetId) =>
  apiBuilder(`${coreBase}/assets/${assetId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  })

export const getAllModelsApi = () =>
  apiBuilder(`${coreBase}/assets/models`, {
    method: 'GET',
    headers: getAuthHeaders()
  })

export const getReportMovements = (payload) => {
  const { assetId, from, to } = payload
  const query = from && to ? `?from=${from}&to=${to}` : ''
  return apiBuilder(
    `${coreBase}/assets/${assetId}/report/movement/all${query}`,
    {
      method: 'GET',
      headers: getAuthHeaders()
    }
  )
}

export const getReportLocations = (payload) => {
  const { assetId, from, to } = payload
  const query = from && to ? `?from=${from}&to=${to}` : ''
  return apiBuilder(
    `${coreBase}/assets/${assetId}/report/timeseries/location${query}`,
    {
      method: 'GET',
      headers: getAuthHeaders()
    }
  )
}

export const getReportWeather = (payload) => {
  const { assetId, from, to } = payload
  const query = from && to ? `?from=${from}&to=${to}` : ''
  return apiBuilder(
    `${coreBase}/assets/${assetId}/report/timeseries/weather${query}`,
    {
      method: 'GET',
      headers: getAuthHeaders()
    }
  )
}

export const getObdReport = (assetId, from, to) => {
  const query = from && to ? `?from=${from}&to=${to}` : ''
  var api = apiBuilder(
    `${coreBase}/assets/${assetId}/Report/Suntech/obd${query}`,
    {
      method: 'GET',
      headers: getAuthHeaders()
    }
  )
  return api
}
export const getStatusReport = (assetId, from, to) => {
  const query = from && to ? `?from=${from}&to=${to}` : ''
  var api = apiBuilder(
    `${coreBase}/assets/${assetId}/Report/Suntech/Status${query}`,
    {
      method: 'GET',
      headers: getAuthHeaders()
    }
  )
  return api
}

export const GetRutxReport = (assetId, from, to) => {
  const query = from && to ? `?from=${from}&to=${to}` : ''
  var api = apiBuilder(`${coreBase}/assets/${assetId}/Report/Rutx${query}`, {
    method: 'GET',
    headers: getAuthHeaders()
  })
  return api
}
export const GetRutxSensorsState = (assetId) => {
  var api = apiBuilder(`${coreBase}/assets/${assetId}/Report/SensorsState`, {
    method: 'GET',
    headers: getAuthHeaders()
  })
  return api
}

export const GetRutxSensorsLogs = (assetId) => {
  var api = apiBuilder(`${coreBase}/assets/${assetId}/Report/SensorsLogs`, {
    method: 'GET',
    headers: getAuthHeaders()
  })
  return api
}


export const getDriverDetailsApi = (payload) => {

  var api = apiBuilder(`${coreBase}/assets/AssetReport`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({
      id: payload.assetId,
    })
  })
  return api
}