import React, { Component } from 'react';
import { Layout } from 'antd';

import Sidebar from '../Sidebar/index';
import HorizontalDefault from '../Topbar/HorizontalDefault/index';
import HorizontalDark from '../Topbar/HorizontalDark/index';
import InsideHeader from '../Topbar/InsideHeader/index';
import AboveHeader from '../Topbar/AboveHeader/index';
import BelowHeader from '../Topbar/BelowHeader/index';

import Topbar from '../Topbar/index';
import { footerText } from 'util/config';
import App from 'routes/index';
import { connect } from 'react-redux';
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from '../../constants/ThemeSetting';
import NoHeaderNotification from '../Topbar/NoHeaderNotification/index';
import { userSignInRefresh } from 'appRedux/actions/Auth';
import { init } from 'appRedux/actions/Settings';
import loginBgImage from '../../assets/images/login-bg.jpg';
import PasswordDialog from 'routes/auth/login/PasswordDialog';

const { Content, Footer } = Layout;

export class MainApp extends Component {
  componentDidMount() {
    this.props.init();
    if (this.props.refreshToken) {
      this.props.userSignInRefresh({ refreshToken: this.props.refreshToken });
    }
  }

  getContainerClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_BELOW_HEADER:
        return 'gx-container-wrap';
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-container-wrap';
      case NAV_STYLE_MINI_SIDEBAR:
        return 'gx-container-wrap';
      default:
        return '';
    }
  };
  getNavStyles = (navStyle, width, path) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        if (width < TAB_SIZE && path !== '/auth/login') {
          return <Topbar />
        } else {
          return null;
        }
      case NAV_STYLE_DRAWER:
        return null;
      case NAV_STYLE_MINI_SIDEBAR:
        return null;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };

  render() {
    const { match, width, navStyle } = this.props;

    return (
      <Layout className="gx-app-layout">
        {this.getSidebar(navStyle, width)}
        <Layout>
          {this.getNavStyles(navStyle, width, this.props.location.pathname)}
          <Content
            className={`gx-layout-content ${this.getContainerClass(navStyle)} `}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              backgroundImage: this.props.location.pathname == '/auth/login' ? `url(${loginBgImage})` : 'none'
            }}>
            <App match={match} />
            {this.props.location.pathname !== '/auth/login' ? (
              <Footer>
                <div className="gx-layout-footer-content">{footerText}</div>
              </Footer>
            ) : null}
            <PasswordDialog />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ settings, auth, routing }) => {
  const { width, navStyle } = settings;
  const { refreshToken } = auth;
  const { location } = routing;
  return { width, navStyle, refreshToken, location };
};
export default connect(
  mapStateToProps,
  { init, userSignInRefresh }
)(MainApp);
