import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from '../../constants/ThemeSetting';

const SubMenu = Menu.SubMenu;

class HorizontalNav extends Component {
  getNavStyleSubMenuClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return 'gx-menu-horizontal gx-submenu-popup-curve';
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve';
      case NAV_STYLE_BELOW_HEADER:
        return 'gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve';
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve';
      default:
        return 'gx-menu-horizontal';
    }
  };

  render() {
    const { pathname, navStyle } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} mode="horizontal">
        {/* <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="auth"
          title={<IntlMessages id="sidebar.auth" />}>
          <Menu.Item key="auth.login">
            <Link to="/auth/login">
              <i className="icon icon-auth-screen" />
              <IntlMessages id="sidebar.auth.login" />
            </Link>
          </Menu.Item>
        </SubMenu> */}

        <SubMenu
          className={this.getNavStyleSubMenuClass(navStyle)}
          key="teams"
          title={<IntlMessages id="sidebar.teams" />}>
          <Menu.Item key="teams.main">
            <Link to="/teams">
              <i className="icon icon-team" />
              <IntlMessages id="sidebar.teams.main" />
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="teams.settings">
            <Link to="/teams/settings">
              <i className="icon icon-setting" />
              <IntlMessages id="sidebar.teams.settings" />
            </Link>
          </Menu.Item>
          <Menu.Item key="teams.detail">
            <Link to="/teams/detail">
              <i className="icon icon-select" />
              <IntlMessages id="sidebar.teams.detail" />
            </Link>
          </Menu.Item> */}
        </SubMenu>

        {this.props.user.role === 'SysAdmin' ? (
          <SubMenu
            className={this.getNavStyleSubMenuClass(navStyle)}
            key="admin"
            title={<IntlMessages id="sidebar.admin" />}>
            <Menu.Item key="admin.licences">
              <Link to="/admin/licences">
                <i className="icon icon-files" />
                <IntlMessages id="sidebar.admin.licences" />
              </Link>
            </Menu.Item>
            <Menu.Item key="admin.users">
              <Link to="/admin/users">
                <i className="icon icon-user-o" />
                <IntlMessages id="sidebar.admin.users" />
              </Link>
            </Menu.Item>
            <Menu.Item key="admin.teams">
              <Link to="/admin/teams">
                <i className="icon icon-team" />
                <IntlMessages id="sidebar.admin.teams" />
              </Link>
            </Menu.Item>
            <Menu.Item key="admin.tariff">
              <Link to="/admin/tariff">
                <i className="icon icon-tag-o" />
                <IntlMessages id="sidebar.admin.tariff" />
              </Link>
            </Menu.Item>
            <Menu.Item key="admin.device">
              <Link to="/admin/device">
                <i className="icon icon-phone" />
                <IntlMessages id="sidebar.admin.device" />
              </Link>
            </Menu.Item>
          </SubMenu>
        ) : null}
      </Menu>
    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { themeType, navStyle, pathname, locale } = settings;
  const { user } = auth;
  return { themeType, navStyle, pathname, locale, user };
};
export default connect(mapStateToProps)(HorizontalNav);
