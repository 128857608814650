import { GET_ALL_ALERTS_SUCCESS, FILTER_ALERTS, SIGNOUT_USER, GET_ALERT_SETTINGS_SUCCESS } from 'constants/ActionTypes';

const INIT_STATE = {
  allAlerts: [],
  filteredAlerts: [],
  onlyUnread: false,
  alertSettings: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_ALERTS_SUCCESS: {
      return {
        ...state,
        allAlerts: action.payload,
        filteredAlerts: action.payload
      };
    }
    case FILTER_ALERTS: {
      return {
        ...state,
        onlyUnread: Boolean(action.payload),
        filteredAlerts: Boolean(action.payload) ? state.allAlerts.filter(alert => !alert.isRead) : state.allAlerts
      };
    }
    case GET_ALERT_SETTINGS_SUCCESS: {
      return {
        ...state,
        alertSettings: action.payload
      };
    }
    case SIGNOUT_USER: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
