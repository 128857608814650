import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_ALL_USERS,
  GET_USER_DETAILS,
  GET_ALL_USER_ROLES,
  GET_ALL_USER_TENANTS,
  CREATE_USER,
  EDIT_USER,
  DELETE_USER
} from 'constants/ActionTypes';
import {
  getAllUsers,
  getAllUsersSuccess,
  getUserDetailsSuccess,
  getAllUserRolesSuccess,
  getAllUserTenantsSuccess,
  createUserSuccess
} from '../actions/Users';
import { showMessage, hideLoader, showLoader } from '../actions/Settings';
import {
  getAllUsersApi,
  getUserDetailsApi,
  getAllUserRolesApi,
  getAllUserTenantsApi,
  createUserApi,
  editUserApi,
  deleteUserApi
} from '../../api';
import { history } from 'appRedux/store';

function* getAllUsersHandler() {
  try {
    const res = yield call(getAllUsersApi);
    yield put(getAllUsersSuccess(res.users));
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* getUserDetailsHandler({ payload }) {
  const { userId, form } = payload;
  try {
    const res = yield call(getUserDetailsApi, userId);
    if (form) form.setFieldsValue({ ...res });
    yield put(getUserDetailsSuccess(res));
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* getAllUserRolesHandler() {
  try {
    const res = yield call(getAllUserRolesApi);
    yield put(getAllUserRolesSuccess(res.roles));
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* getAllUserTenantsHandler() {
  try {
    const res = yield call(getAllUserTenantsApi);
    yield put(getAllUserTenantsSuccess(res.tenants));
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* createUserHandler({ payload }) {
  yield put(showLoader());
  try {
    const res = yield call(createUserApi, payload);
    yield all([put(createUserSuccess(res)), put(hideLoader())]);
    // history.push('/users');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* editUserHandler({ payload }) {
  yield put(showLoader());
  try {
    yield call(editUserApi, payload);
    yield put(hideLoader());
    history.push('/users');
  } catch (err) {
    yield all([put(showMessage(err.message)), put(hideLoader())]);
  }
}

function* deleteUserHandler({ payload }) {
  try {
    const res = yield call(deleteUserApi, payload);
    yield put(getAllUsers(res));
  } catch (err) {
    yield put(showMessage(err.message));
  }
}

export function* getAllUsersSaga() {
  yield takeEvery(GET_ALL_USERS, getAllUsersHandler);
}

export function* getUserDetailsSaga() {
  yield takeEvery(GET_USER_DETAILS, getUserDetailsHandler);
}

export function* getAllUserRolesSaga() {
  yield takeEvery(GET_ALL_USER_ROLES, getAllUserRolesHandler);
}

export function* getAllUserTenantsSaga() {
  yield takeEvery(GET_ALL_USER_TENANTS, getAllUserTenantsHandler);
}

export function* createUserSaga() {
  yield takeEvery(CREATE_USER, createUserHandler);
}

export function* editUserSaga() {
  yield takeEvery(EDIT_USER, editUserHandler);
}

export function* deleteUserSaga() {
  yield takeEvery(DELETE_USER, deleteUserHandler);
}

export default function* rootSaga() {
  yield all([
    fork(getAllUsersSaga),
    fork(getUserDetailsSaga),
    fork(getAllUserRolesSaga),
    fork(getAllUserTenantsSaga),
    fork(createUserSaga),
    fork(editUserSaga),
    fork(deleteUserSaga)
  ]);
}
