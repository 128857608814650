import { all, fork, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { INIT, SHOW_MESSAGE } from 'constants/ActionTypes';

function* init() {
  console.log('init');
  // history.push('/auth/login');
}

function* handleShowMessage({ payload }) {
  message.error(payload.toString());
}

export function* initSaga() {
  yield takeEvery(INIT, init);
}

export function* showMessageSaga() {
  yield takeEvery(SHOW_MESSAGE, handleShowMessage);
}

export default function* rootSaga() {
  yield all([fork(initSaga), fork(showMessageSaga)]);
}
