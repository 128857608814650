import React, { Component } from 'react';
import { Avatar, Popover } from 'antd';
import { connect } from 'react-redux';
import { userSignOut, authOpenDialog } from 'appRedux/actions/Auth';
import { NAV_STYLE_MINI_SIDEBAR } from 'constants/ThemeSetting';
import logoImage from '../../assets/images/logo-square.png';

class UserProfile extends Component {
  signOut() {
    this.props.userSignOut({ history: this.props.history });
  }

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.authOpenDialog('change')}>Change Password</li>
        <li onClick={() => this.props.userSignOut()}>Sign Out</li>
      </ul>
    );

    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          {this.props.navStyle === NAV_STYLE_MINI_SIDEBAR ? (
            <Avatar src={logoImage} className="gx-size-40 gx-pointer gx-mr-3" alt="" />
          ) : null}
          <span className="gx-avatar-name" style={{ color: 'white' }}>
            {this.props.user && this.props.user.fullName
              ? this.props.user.fullName.firstName + ' ' + this.props.user.fullName.lastName
              : ''}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { user } = auth;
  const { navStyle } = settings;
  return { user, navStyle };
};
export default connect(
  mapStateToProps,
  { userSignOut, authOpenDialog }
)(UserProfile);
