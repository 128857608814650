import { all } from 'redux-saga/effects';
import settingsSagas from './Settings';
import authSagas from './Auth';
import assetsSagas from './Assets';
import routerSagas from './Router';
import usersSagas from './Users';
import sensorsSagas from './Sensors';
import alertsSagas from './Alerts';
import mapSagas from './Map';
import geofencesSagas from './Geofences';

export default function* rootSaga() {
  yield all([
    settingsSagas(),
    routerSagas(),
    authSagas(),
    assetsSagas(),
    usersSagas(),
    sensorsSagas(),
    alertsSagas(),
    mapSagas(),
    geofencesSagas()
  ]);
}
