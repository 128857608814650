import { LOCATION_CHANGE } from 'react-router-redux';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { history } from 'appRedux/store';
import { initPath } from '../actions/Router';

function* locationChange({ payload }) {
  const { pathname, search } = payload;
  const state = yield select();
  const { auth } = state;
  if (!auth.accessToken && pathname !== '/auth/login') {
    if (pathname !== '/' && pathname !== '/auth/login') yield put(initPath(pathname + search));
    setTimeout(() => {
      history.push('/auth/login');
    }, 100);
  }
}

export function* locationChangeSaga() {
  yield takeEvery(LOCATION_CHANGE, locationChange);
}

export default function* rootSaga() {
  yield all([fork(locationChangeSaga)]);
}
